import {
  Address,
  AddressComponent,
  AddressOutput,
  Geometry,
  BaseMapsClient,
  PlaceDetails,
} from 'wix-ui-core/dist/src/clients/GoogleMaps/types';
import { AddressLocation, V2Prediction, WixAtlasServiceWeb } from '@wix/ambassador-wix-atlas-service-web/http';
import { Address as OloAddress } from '@wix/restaurants-client-logic';
import {
  SubdivisionType,
  V2GetPlaceRequest,
  V2GetPlaceResponse,
  V2Place,
} from '@wix/ambassador-wix-atlas-service-web/types';
import { InternalAddress } from 'wix-ui-core/src/clients/GoogleMaps/types';
import OPU from '@wix/wixrest-utils/dist/Properties';
import { WidgetAndSettingsFedopsLogger } from 'yoshi-flow-editor-runtime/build/esm/controller';

export const BASE_URL = '/api/wix-atlas-service-web';
export const EDITOR_BASE_URL = '/wix-atlas-service-web';

export const PREDICTIONS_RADIUS = 50000;

function getBaseUrl() {
  let isEditor = false;

  try {
    isEditor = typeof window !== 'undefined' && window.location.host === 'editor.wix.com';
  } catch (e) {}

  return isEditor ? EDITOR_BASE_URL : BASE_URL;
}

interface AtlasClientInterface extends BaseMapsClient<InternalAddress> {
  origin?: AddressLocation;
}

export function getAtlasClientConstructor(
  signedInstance: string,
  locale?: string,
  origin?: AddressLocation,
  fedopsLogger?: WidgetAndSettingsFedopsLogger,
) {
  class AtlasClient implements AtlasClientInterface {
    wixAtlasServiceWeb: ReturnType<typeof WixAtlasServiceWeb>;
    origin?: AddressLocation;

    constructor() {
      this.wixAtlasServiceWeb = WixAtlasServiceWeb(getBaseUrl());
      this.origin = origin;
    }

    async autocomplete(apiKey: string, lang: string, request: any): Promise<Address[]> {
      if (request.input.length <= 1) {
        return [];
      }

      const autocompleteServiceV2 = await this.wixAtlasServiceWeb.AutocompleteServiceV2()({
        Authorization: signedInstance,
        'x-wix-linguist': `${lang}|${locale || 'en-US'}|false|${signedInstance}`,
      });
      fedopsLogger && fedopsLogger.interactionStarted('atlas-autocomplete-address');
      const listPredictionsResponse = await autocompleteServiceV2.predict({
        input: request.input,
        location: this.origin,
        origin: this.origin,
        radius: this.origin !== undefined ? PREDICTIONS_RADIUS : undefined,
      });
      fedopsLogger && fedopsLogger.interactionEnded('atlas-autocomplete-address');

      return (listPredictionsResponse.predictions || []).map(convertPredictionToAddress);
    }

    geocode(apiKey: string, lang: string, request: any): Promise<InternalAddress[]> {
      return new Promise((resolve) => resolve([]));
    }

    async placeDetails(apiKey: string, lang: string, request: any): Promise<PlaceDetails> {
      const placesServiceV2 = this.wixAtlasServiceWeb.PlacesServiceV2()({
        Authorization: signedInstance,
        'x-wix-linguist': `${lang}|${locale || 'en-US'}|false|${signedInstance}`,
      });
      const getPlaceRequest: V2GetPlaceRequest = {
        searchId: typeof request === 'string' ? request : request.searchId || request.placeId,
      };
      const response: V2GetPlaceResponse = await placesServiceV2.getPlace(getPlaceRequest);
      return convertPlaceToPlaceDetails(response.place);
    }

    useClientId(): void {}

    name = 'google';
  }

  return AtlasClient;
}

function constructAddressComponents(place: V2Place): AddressComponent[] {
  const result: AddressComponent[] = [];
  if (place.address) {
    if (typeof place.address.country !== 'undefined' || typeof place.address.countryFullname !== 'undefined') {
      result.push({
        types: ['country'],
        short_name: place.address.country || '',
        long_name: place.address.countryFullname || '',
      });
    }

    if (place.address.streetAddress?.name) {
      result.push({
        types: ['route'],
        short_name: place.address.streetAddress?.name,
        long_name: place.address.streetAddress?.name,
      });
    }

    if (place.address.streetAddress?.number) {
      result.push({
        types: ['street_number'],
        short_name: String(place.address.streetAddress?.number),
        long_name: String(place.address.streetAddress?.number),
      });
    }

    if (place.address.city) {
      result.push({
        types: ['locality'],
        short_name: String(place.address.city),
        long_name: String(place.address.city),
      });
    }

    if (place.address.postalCode) {
      result.push({
        types: ['postal_code'],
        short_name: String(place.address.postalCode),
        long_name: String(place.address.postalCode),
      });
    }

    const adminAreaLevel1 = place.address.subdivisions?.find(
      (sd) => sd.type === SubdivisionType.ADMINISTRATIVE_AREA_LEVEL_1,
    );

    if (place.address.country === 'US' && adminAreaLevel1) {
      result.push({
        types: ['administrative_area_level_1'],
        short_name: String(adminAreaLevel1.code),
        long_name: String(adminAreaLevel1.name),
      });
    }
  }

  return result;
}

export const convertPlaceToPlaceDetails = (place?: V2Place): PlaceDetails => {
  const { address, placeId } = place!;

  return {
    address_components: constructAddressComponents(place!),
    adr_address: '',
    formatted_address: address!.formattedAddress!,
    geometry: convertGeoLocationToGeometry(place!.address!.geocode!),
    html_attributions: '',
    icon: '',
    id: '',
    name: '',
    photos: '',
    place_id: placeId!,
    reference: '',
    scope: '',
    types: [],
    url: '',
    utc_offset: '',
  };
};

const convertGeoLocationToGeometry = (addressLocation: AddressLocation): Geometry => {
  return {
    location: {
      lat: addressLocation.latitude || 0,
      lng: addressLocation.longitude || 0,
    },
  };
};

export const convertPredictionToAddress = (prediction: V2Prediction): Address => {
  return {
    description: prediction.description || '',
    place_id: prediction.searchId || '',
    types: [],
  };
};

export function getAddressFromGoogleResult({ address, googleResult }: AddressOutput): OloAddress {
  const result: OloAddress = {
    formatted: googleResult.formatted_address,
    latLng: googleResult.geometry.location,
    city: address.city,
  };

  if (typeof address.country === 'string') {
    result.countryCode = address.country;
  }

  if (address.streetAddress) {
    result.street = address.streetAddress.name;
    result.number = address.streetAddress.number;
  }

  if (address.postalCode) {
    result.postalCode = address.postalCode;
  }

  if (address.country === 'US' && address.subdivision) {
    OPU.set(result, 'subdivision', address.subdivision);
  }

  return result;
}
